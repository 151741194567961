export const TOKEN_KEY = 'gd.t';
export const INTRO_LOG_KEY = "gd.n";
export const AUDIO_DURATION_LIST = "gd.ad";
export const FORGOT_PASS_KEY = "gd.fp";
export const REDIRECT_KEY = "gd.rd";

// value uurchlult oruulsan bol dashboard drh hamaarah hesgvvd dr bas uurchlult oruulaarai
export const VALIDATE_K = {
  articles: "articles",
  books: "books",
  albums: "albums",
  tags: "tags",
  videos: "videos",
  moods: "moods",
  podcasts: "podcasts",
  courses: "courses",
  packages: "packages",
  lectures: "lectures",
  banners: "banners",
  magazines: "magazines",
  feedbacks: "feedbacks",
  magazine_category: "magazine_categories",
  magazine_article: "magazine_articles",
}
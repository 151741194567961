"use client";

import { forwardRef, ReactNode } from "react";
import { Buy, /* Heart, */ IconProps, User } from "react-iconly";
import Link from "next/link";
import { usePathname } from "next/navigation";

import emptyCartIcon from "@/assets/vectors/empty-cart.svg?url";
import { Button, ButtonProps } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth, useCart } from "@/context";
import { REDIRECT_KEY } from "@/lib/constants";
import { currencyFormat, storage } from "@/lib/utils";

import { LineItem } from "../../single-item";

export default function Actions() {
  const { isAuth } = useAuth();
  const pathname = usePathname();

  if (!isAuth)
    return (
      <Link
        href="/auth/login"
        onClick={() => storage.set(REDIRECT_KEY, pathname)}
        className="h-9 w-20 bg-primary text-white flex items-center justify-center rounded-lg text-xs"
      >
        Нэвтрэх
      </Link>
    );
  return (
    <div className="flex items-center gap-8">
      {/* <ActionItem
        icon={Heart}
        iconProps={{ style: { marginBottom: -2 } }}
        aria-label="Saved items"
      /> */}
      <CartAction />
      <UserAction />
    </div>
  );
}

function UserAction() {
  const { logout } = useAuth();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ActionItem icon={User} aria-label="User profile or sign in" />
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-72 p-3">
        <DropdownMenuGroup>
          <DropdownMenuItem className="!py-0">
            <Link
              href="/account"
              className="font-bold text-sm block flex-1 py-3"
            >
              Би
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem className="!py-0">
            <Link
              href="/account/edit"
              className="font-bold text-sm block flex-1 py-3"
            >
              Миний мэдээлэл
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem className="!py-0">
            <Link
              href="/magazine/purchased"
              className="font-bold text-sm block flex-1 py-3"
            >
              Худалдаж авсан сэтгүүл
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator className="!mx-0 bg-[#DEE2E5]" />
          <DropdownMenuItem className="!py-0">
            <Link
              href="/account/pin"
              className="font-bold text-sm block flex-1 py-3"
            >
              Пин код солих
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem className="!py-0">
            <Link
              href="/account/faq"
              className="font-bold text-sm block flex-1 py-3"
            >
              Нийтлэг асуулт хариулт
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem className="!py-0">
            <button
              type="button"
              className="font-bold text-sm text-[#E76A6A] flex-1 text-left py-3"
              onClick={logout}
            >
              Гарах
            </button>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function CartAction() {
  const { items, totalPrice } = useCart();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ActionItem
          icon={Buy}
          badge={items.length}
          aria-label="Cart items dropdown"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[410px] p-3 flex flex-col gap-8">
        <div className="flex items-center justify-between gap-1">
          <h3 className="font-bold text-2xl text-black">Таны сагс</h3>
          <span className="text-primary underline text-sm">
            Бүтээгдэхүүн ({items.length})
          </span>
        </div>
        <div className="space-y-4 max-h-[60dvh] overflow-y-auto">
          {!items.length && (
            <div className="text-center space-y-9 my-6">
              <img
                src={emptyCartIcon.src}
                alt="cart empty"
                className="mx-auto"
              />
              <p className="text-[#84807D] text-sm">
                Таны сагс хоосон байна...
              </p>
            </div>
          )}
          {items.map((c, idx) => (
            <LineItem
              key={idx}
              price={c.price}
              productType={c.productType}
              productId={c.productId}
              title={c.productName}
              thumbnail={c.thumbImg}
              loading={c.adding || c.removing}
            />
          ))}
        </div>
        {!!items.length && (
          <div>
            <div className="flex items-center justify-between gap-2 mb-3">
              <span className="text-[#84807D] text-sm">Нийт төлөх дүн:</span>
              <span className="text-primary text-sm font-bold">
                {currencyFormat(totalPrice)}
              </span>
            </div>
            <Link
              href="/cart"
              className="flex items-center justify-center bg-primary text-primary-foreground font-bold h-12 rounded-xl"
            >
              Худалдаж авах
            </Link>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

interface ActionItemProps extends ButtonProps {
  icon: React.FC<IconProps>;
  iconProps?: IconProps;
  badge?: ReactNode;
}

const ActionItem = forwardRef<HTMLButtonElement, ActionItemProps>(
  ({ icon: IconComponent, iconProps = {}, badge, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        className="rounded-full bg-[#FBF9F8] aspect-square h-9 p-0 flex items-center justify-center hover:bg-slate-100 relative"
        size="sm"
        {...rest}
      >
        {!!badge && (
          <span className="absolute rounded-full bg-primary -right-1 -top-1 text-xs text-white px-1 font-bold min-w-4 h-4 leading-none flex items-center justify-center">
            {badge}
          </span>
        )}
        <IconComponent primaryColor="black" size={24} {...iconProps} />
      </Button>
    );
  }
);

ActionItem.displayName = "ActionItem";

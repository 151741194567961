import dayjs from "dayjs";
import { User } from "lucide-react";
import Image from "next/image";

import { ExpandableText, ExpandableTextSkeleton } from "@/components/custom";
import { apiImage } from "@/lib/utils";

export function FeedbackItem({
  comment,
  nickname,
  label,
  date,
  avatar,
}: {
  comment: string;
  avatar: string;
  nickname: string;
  label: string;
  date: string;
}) {
  return (
    <article itemScope itemType="https://schema.org/Comment">
      <div className="flex items-start gap-4 mb-4">
        <div
          itemProp="author"
          itemScope
          itemType="https://schema.org/Person"
          className="flex-shrink-0"
        >
          {avatar ? (
            <Image
              src={apiImage(avatar, "xs")}
              alt="User avatar"
              width={32}
              height={32}
              loading="lazy"
              className="rounded-full overflow-hidden aspect-square object-cover"
              itemProp="image"
              unoptimized
            />
          ) : (
            <div className="size-8 rounded-full bg-slate-100 flex items-center justify-center">
              <User size={16} />
            </div>
          )}
          <meta itemProp="name" content={nickname} />
        </div>

        <div className="flex-1 min-w-0 flex items-start justify-between">
          <div>
            <h3 className="text-sm font-semibold leading-none" itemProp="name">
              {nickname}
            </h3>
            <p className="text-xs text-primary">{label}</p>
          </div>
          <time
            itemProp="datePublished"
            dateTime={dayjs(date).format("YYYY-MM-DD")}
            className="text-sm text-[#84807D]"
          >
            {dayjs(date).format("YYYY.MM.DD")}
          </time>
        </div>
      </div>
      <ExpandableText text={comment} lineClamp={3} />
    </article>
  );
}

export function FeedbackItemSkeleton() {
  return (
    <article className="animate-pulse">
      <div className="flex items-start gap-4 mb-4">
        <div className="w-8 h-8 bg-slate-100 rounded-full" />

        <div className="flex-1 min-w-0 flex items-start justify-between">
          <div>
            <div className="w-3/4 bg-slate-100 h-4 rounded-md" />
            <div className="w-1/4 bg-slate-100 h-3 rounded-md" />
          </div>
          <div className="w-1/4 bg-slate-100 h-3 rounded-md" />
        </div>
      </div>
      <ExpandableTextSkeleton />
    </article>
  );
}
